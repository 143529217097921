<template>
  <fw-layout :back-to-enable="false" mobile-ready footer>
    <template #main-content>
      <PanelUCIdHero :user="user" class="mt-5 mb-10" version="v2" :stats="stats" />
      <fw-panel title="Acesso rápido">
        <div class="grid grid-cols-2 md:grid-cols-4 gap-3 justify-center mb-3 items-stretch">
          <button-quick-access
            v-if="meeting && meeting.key"
            :label="meeting.running ? 'Sala aberta' : 'Sala fechada'"
            title="Sala Pessoal"
            :footer="meeting.key_alias"
            @clicked="$router.push({ name: 'live', params: { key: meeting.key_alias } })"
          />
          <button-quick-access-enter-room />
          <button-quick-access label="Comunicação" title="Grupos" @clicked="$router.push({ name: 'groups' })" />
          <button-quick-access label="Comunicação" title="Canais" @clicked="$router.push({ name: 'chats' })" />
          <button-quick-access label="Recursos" title="Ficheiros" @clicked="$router.push({ name: 'buckets' })" />
          <button-quick-access label="Ferramentas" title="Gravações" @clicked="$router.push({ name: 'recordings' })" />
          <button-quick-access label="Suporte" title="Precisa de ajuda?" @clicked="$router.push('/support')" />
        </div>
      </fw-panel>
    </template>
  </fw-layout>
</template>

<script>
import ButtonQuickAccess from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccess'
import ButtonQuickAccessEnterRoom from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccessEnterRoom'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import PanelUCIdHero from '@/fw-modules/fw-core-vue/id/components/panels/PanelUCIdHero'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'

export default {
  components: {
    PanelUCIdHero,
    ButtonQuickAccess,
    ButtonQuickAccessEnterRoom
  },

  data() {
    this.loadStats()
    return {
      stats: [
        {
          key: 'meetings-meetings',
          label: 'Sessões virtuais',
          value: '0',
          valueSuffix: null,
          featured: false,
          description: 'Via UC Tx / Mt / St'
        },
        {
          key: 'meetings-hours',
          label: 'Horas virtuais',
          value: '0',
          valueSuffix: 'h',
          featured: false,
          description: ''
        }
      ]
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    meeting() {
      if (this.user && this.user.meeting) return this.user.meeting
      else return null
    }
  },

  mounted() {
    this.$store.dispatch('setUser')
    ServiceSettings.checkAppVersion()
  },

  methods: {
    async loadStats() {
      const user = this.$store.getters.getUser
      if (!user || !user.key) return

      const stats = {}
      try {
        const meetingStats = await ServiceMeetings.getStats()
        for (let [key, value] of Object.entries(meetingStats)) {
          if (key == 'attend_seconds') stats['meetings-hours'] = Math.round(value / 60 / 60)
          else stats[`meetings-${key}`] = value
        }
      } catch (error) {
        console.error('Failed to get meetings stats', error)
      }

      for (const stat of this.stats) {
        const value = stats[stat.key]
        if (value) stat.value = String(value)
      }
    }
  }
}
</script>
