<template>
  <LayoutDefault :main-footer="true" :main-sidebar="false" :full="true" :force-extended-app-logo="true">
    <template #main-content>
      <div class="w-full h-full flex flex-col items-center justify-center">
        <div class="md:bg-white md:shadow-2xl p-5 md:p-10 md:rounded-xl" style="min-width: 300px; max-width: 320px">
          <fw-heading :size="!meetingTitle ? 'h2' : 'h3'" class="text-center mb-8">{{ $t('enterAsGuest') }}</fw-heading>
          <div v-if="meetingTitle" class="has-margin-top-medium has-text-centered">
            <div class="label is-tiny is-marginless">{{ $t('virtualRoom') }}</div>
            <div class="is-size-2 has-text-weight-bold has-margin-bottom-small">
              {{ meetingTitle }}
            </div>
            <div class="label is-tiny is-marginless">{{ $t('roomId') }}</div>
            <div class="is-size-4 is-lowercase is-family-code">{{ meetingKey }}</div>
            <div>
              <a class="text-xs text-primary" @click.prevent="$router.push({ name: 'anonymous' }, resetMeeting)">
                {{ $t('otherRoomId') }}
              </a>
            </div>
          </div>

          <form class="has-margin-top-medium is-full" method="post" @submit.prevent="doJoin">
            <b-field
              v-if="!meetingTitle"
              :label="$t('roomId')"
              label-for="meetingKey"
              :type="{ 'is-danger': meetingKeyError }"
              :message="meetingKeyError"
            >
              <b-input v-model="meetingKey" expanded type="text"></b-input>
            </b-field>
            <b-field
              v-if="meetingTitle && codeRequired"
              :label="$t('accessCode')"
              label-for="code"
              :type="{ 'is-danger': codeError, 'has-text-muted has-text-tiny': !codeError }"
              :message="codeError ? codeError : 'Indique o código de acesso à sala.'"
            >
              <b-input v-model="code" expanded type="text"></b-input>
            </b-field>
            <b-field
              v-if="meetingTitle"
              :label="$t('name')"
              label-for="name"
              :type="{ 'is-danger': nameError, 'has-text-muted has-text-tiny': !nameError }"
              :message="nameError ? nameError : $t('nameInstructions')"
            >
              <b-input v-model="name" expanded type="text"></b-input>
            </b-field>
            <b-field>
              <b-button
                expanded
                :loading="loading"
                type="is-primary"
                size="is-medium"
                :disabled="!isMeetingKeyValid"
                @click="doJoin"
              >
                {{ $t('continue') }}
              </b-button>
            </b-field>
          </form>
          <div class="has-margin-top-medium">
            <hr />
            <div class="label is-tiny">{{ $t('haveUCAccountQuestion') }}</div>
            <div></div>
            <b-button
              type="is-light"
              expanded
              @click="$router.push({ name: 'login', query: { redirect: `/live/${meetingKey}` } })"
              >{{ $t('enterWithUCAccount') }}</b-button
            >
          </div>
        </div>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    LayoutDefault
  },

  data() {
    return {
      loading: false,
      meetingKey: this.$route.params.key || null,
      meetingKeyError: null,
      meetingTitle: null,
      codeRequired: true,
      code: this.$route.params.code || null,
      codeError: null,
      name: null,
      nameError: null
    }
  },

  computed: {
    isMeetingKeyValid() {
      return this.meetingKey && this.meetingKey.length >= 3
    },
    isMobile() {
      return this.$device.isMobile() && !this.$device.isTablet()
    }
  },

  created() {
    if (this.meetingKey) this.doJoin()
  },

  methods: {
    resetMeeting() {
      this.meetingKey = null
      this.meetingTitle = null
      this.meetingKeyError = null
      this.code = null
      this.codeError = null
      this.name = null
      this.nameError = null
    },

    async doJoin() {
      this.loading = true
      this.meetingKeyError = null
      this.codeError = null
      this.nameError = null

      try {
        if (!this.meetingKey) {
          this.meetingKeyError = this.$t('message.form.required')
          this.meetingTitle = null
        } else {
          try {
            const errorResponse = await ServiceMeetings.authAnonymous(this.meetingKey, this.name, { code: this.code })
            if (errorResponse) {
              const firstTry = !this.meetingTitle
              this.codeRequired = errorResponse.code_required
              this.meetingTitle = errorResponse.title

              if (!firstTry) {
                const errors = utils.errors(errorResponse).setContext('meeting')
                this.codeError = errors.getFieldTranslated('code')
                this.nameError = errors.getFieldTranslated('name')
              }
            } else {
              this.$router.push({
                name: 'live',
                params: { key: this.meetingKey }
              })
              return
            }
          } catch (error) {
            this.meetingKeyError = utils
              .errors(error)
              .setContext('meeting')
              .getTranslated()
            this.meetingTitle = null
          }
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<i18n>
  {
    "en": {
      "name": "Your name",
      "nameInstructions": "Indicate the name by which you wish to be identified in the virtual session.",
      "enterAsGuest": "Enter as guest",
      "accessCode": "Access code",
      "continue": "Continue",
      "haveUCAccountQuestion": "Do you have a UC account?",
      "enterWithUCAccount": "Enter with UC account",
      "roomId": "Room ID",
      "virtualRoom": "Virtual room",
      "otherRoomId": "Enter another room ID",
      "message": {
        "form": {
          "required": "This field is required."
        }
      }
    },
    "pt": {
      "name": "O seu nome",
      "nameInstructions": "Indique o nome pelo qual pretende ser identificado na sessão virtual.",
      "enterAsGuest": "Entrar como convidado",
      "accessCode": "Código de acesso",
      "continue": "Continuar",
      "haveUCAccountQuestion": "Tem uma conta institucional da Universidade de Coimbra?",
      "enterWithUCAccount": "Entrar com conta UC",
      "roomId": "ID da sala",
      "virtualRoom": "Sala virtual",
      "otherRoomId": "Indicar outro ID",
      "message": {
        "form": {
          "required": "Este campo é obrigatório."
        }
      }
    }
  }
  </i18n>
